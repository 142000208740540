@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* width: 100vw;
  overflow:hidden */
}

font-dmsans {
  font-family: "DM Sans", 'sans-serif';

}


@font-face {
  font-family: "mn_semibold";
  src: url("./font/MNF/normal/MaisonNeue-Bold.otf");
}

@font-face {
  font-family: "mn_medium";
  src: url("./font/MNF/normal/MaisonNeue-Medium.otf");
}

body {
  overflow-x: hidden !important;
  width: 100vw !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
/* * { */
  /* outline: 1px solid red; */
/* } */

body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}
.font-mn_semibold {
  font-family: "mn_semibold";
}

.font-mn_medium {
  font-family: "mn_medium";

}

.hero-section {
  /* background: url('../public/images/'); */
  background: url('/public/images/bg.svg'), #1a327a;

}

.hero-section_contact {
  background: url('/public/images/bg.svg'), #000;
}

.hero-text {
  background: linear-gradient(90deg, #FFFFFF 0%, #8BA9FF 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'mn_semibold';

}

.hero-text_contact {
  background: linear-gradient(90deg, #FFFFFF 0%, #AFAFAF 100%) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

.exist-section {
  background: url('/public/images/exist_bg.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.our_services_section {
  background-image: url("/public/images/services_bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: -1;
}

.our_services_section::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0B2161E0;
  z-index: -1;
}

.work-container {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  /* z-index: -1; */
}

.work-container::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(48, 76, 159, 0) 0%, rgba(10, 39, 122, 0.87) 100%);
  cursor: pointer;
  /* z-index: -1; */
}

.work-container.work_1 {
  background-image: url("/public/images/work_1.jpeg");

}

.work-container.work_2 {
  background-image: url("/public/images/work_5.png");

}

.work-container.work_3 {
  background-image: url("/public/images/work_3.jpeg");

}
.work-container.work_4 {
  background-image: url("/public/images/work_4.png");

}

.business-card_child {
  background-color: #21409A8F;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
}

.business-card-1,
.business-card-2 {
  background: linear-gradient(180deg, rgba(33, 64, 154, 0.081) 0%, rgba(33, 64, 154, 0.3) 100%);
  background-repeat: no-repeat !important;
  background-size: cover !important;

}

.business-card-1 {
  background: url('/public/images/b1.svg');

}

.business-card-2 {
  background: url('/public/images/b2.svg');
}

@media (max-width:969px) {
  .business-card-1 {
    background: url('/public/images/m012.png');

  }

  .business-card-2 {
    background: url('/public/images/mo13.png');
  }
}